<template>
  <div class="terms">
      <div class="container">
          <div class="row mt-5 mb- 5">
            <div class="col-md-12">

              <div class="shadow-lg p-5 mb-5 bg-white rounded">

                  <form-wizard action="#" @onComplete="submitCover" @onNextStep="nextStep" @onPreviousStep="previousStep">
                      <tab-content title="Driver Details" :selected="true">
                          <div class="p-3">
                              <div class="row">
                                  <div class="col-md-6">
                                      <label><strong>Vehicle Registration:<sup>*</sup></strong> </label> <br/>
                                      <input type="text" class="form-control" v-model="coverDetails.vehicleReg" />
                                  </div>
                                  <div class="col-md-6">
                                      <label><strong>Full Names: <sup>*</sup> </strong> </label> <br/>
                                      <input type="text" class="form-control" v-model="coverDetails.fullNames" />
                                  </div>

                              </div>
                              <div class="row mt-3">
                                  <div class="col-md-6">
                                      <label><strong>Mobile Number: <sup>*</sup></strong> </label> <br/>
                                      <input type="text" class="form-control" v-model="coverDetails.mobileNumber" />
                                  </div>
                                  <div class="col-md-6">
                                      <label><strong>ID Type:</strong> </label> <br/>
                                      <select class="form-control" v-model="coverDetails.idType">
                                          <option value=""> -- Select -- </option>
                                          <option value="National Id"> National Id</option>
                                          <option value="Alien Id"> Alien Id</option>
                                          <option value="Passport">  Passport</option>
                                      </select>
                                  </div>
                              </div>
                              <div class="row mt-3">
                                  <div class="col-md-12">

                                      <div v-if="coverDetails.idType">
                                          <label v-if="coverDetails.idType == 'National Id'"><strong>National ID:</strong> </label>
                                          <label v-else-if="coverDetails.idType == 'Alien Id'"><strong>Alien ID:</strong> </label>
                                          <label v-else-if="coverDetails.idType == 'Passport'"><strong>Passport:</strong> </label>
                                          <br/>
                                          <input type="text" class="form-control" v-model="coverDetails.identificationNumber" />
                                      </div>


                                  </div>
                              </div>
                              <div class="row mt-3">
                                  <div class="col-md-6">
                                      <label><strong>Email Address: <sup>*</sup></strong> </label> <br/>
                                      <input type="text" class="form-control" v-model="coverDetails.email" />
                                  </div>
                                  <div class="col-md-6">
                                      <label><strong>Gender: <sup>*</sup></strong> </label> <br/>
                                      <select class="form-control" v-model="coverDetails.gender">
                                          <option value=""> -- Select -- </option>
                                          <option value="male"> Male</option>
                                          <option value="female"> Female</option>
                                      </select>
                                  </div>
                              </div>
                              <div class="row mt-3">
                                  <div class="col-md-12">
                                      <label><strong>Vehicle Make:</strong> </label> <br/>
                                      <v-select :value="coverDetails.model"   label="brand" :options="car_brands"></v-select>
                                  </div>
                              </div>
                          </div>
                      </tab-content>

                      <tab-content title="Benefits">
                          <div class="row p-3">
                              <div class="col-md-12 ">
                                  <h5>
                                      Other than damages caused on your car as a result of collision, comprehensive insurance also covers damages caused by:
                                  </h5>

                                  <div class="mt-4">
                                      <table class="table table-bordered">
                                          <tr>
                                              <th>1</th>
                                              <td>Theft</td>
                                          </tr>
                                          <tr>
                                              <th>2</th>
                                              <td>Vandalism</td>
                                          </tr>
                                          <tr>
                                              <th>3</th>
                                              <td>Fire</td>
                                          </tr>
                                          <tr>
                                              <th>4</th>
                                              <td>Natural disasters (like a hurricane or a tornado)</td>
                                          </tr>
                                          <tr>
                                              <th>5</th>
                                              <td>Falling objects</td>
                                          </tr>
                                          <tr>
                                              <th>6</th>
                                              <td>Damage done to your car by animals</td>
                                          </tr>
                                          <tr>
                                              <th>7</th>
                                              <td>A civil disturbance (like a riot that results in damage or destruction of your car)</td>
                                          </tr>
                                          <tr>
                                              <th>8</th>
                                              <td>In case of vehicle theft, caught on fireF, vandalism</td>
                                          </tr>
                                          <tr>
                                              <th>9</th>
                                              <td>In damage due to natural calamities</td>
                                          </tr>
                                      </table>
                                  </div>

                              </div>
                          </div>
                      </tab-content>

                      <tab-content title="Summary">
                          <div class="p-3">
                              <div class="row">
                              <div class="col-md-6">
                                  <label><strong>Vehicle Registration:<sup>*</sup></strong> </label> <br/>
                                  {{ coverDetails.vehicleReg }}
                              </div>
                              <div class="col-md-6">
                                  <label><strong>Full Names: <sup>*</sup> </strong> </label> <br/>
                                  {{ coverDetails.fullNames }}
                              </div>

                          </div>
                          <div class="row mt-3">
                              <div class="col-md-6">
                                  <label><strong>Mobile Number: <sup>*</sup></strong> </label> <br/>
                                  {{ coverDetails.mobileNumber }}
                              </div>
                              <div class="col-md-6">
                                  <label><strong>ID Type:</strong> </label> <br/>
                                  {{ coverDetails.idType }}
                              </div>
                          </div>
                          <div class="row mt-3">
                              <div class="col-md-12">

                                  <div v-if="coverDetails.idType">
                                      <label v-if="coverDetails.idType == 'National Id'"><strong>National ID:</strong> </label>
                                      <label v-else-if="coverDetails.idType == 'Alien Id'"><strong>Alien ID:</strong> </label>
                                      <label v-else-if="coverDetails.idType == 'Passport'"><strong>Passport:</strong> </label>
                                      <br/>
                                      {{ coverDetails.identificationNumber }}
                                  </div>


                              </div>
                          </div>
                          <div class="row mt-3">
                              <div class="col-md-6">
                                  <label><strong>Email Address: <sup>*</sup></strong> </label> <br/>
                                  {{ coverDetails.email }}
                              </div>
                              <div class="col-md-6">
                                  <label><strong>Gender: <sup>*</sup></strong> </label> <br/>
                                  {{ coverDetails.gender }}
                              </div>
                          </div>
                          <div class="row mt-3">
                              <div class="col-md-12">
                                  <label><strong>Vehicle Make:</strong> </label> <br/>
                                  {{ coverDetails.model }}
                              </div>
                          </div>
                          </div>
                      </tab-content>
                      <tab-content title="Payment">
                          <div class="p-3">
                              <div class="row">
                                  <div class="col-md-12">
                                      <label>
                                          <strong>Payment Type:</strong>
                                      </label> <br/>
                                      <label for="m-pesa"><input v-model="coverDetails.payment_type" id="m-pesa" type="radio"  value="mpesa" /> M-pesa </label>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label for="card"><input v-model="coverDetails.payment_type" id="card" type="radio"  value="card" /> Card </label>
                                  </div>
                                  <div class="col-md-12 mt-3" v-if="coverDetails.payment_type == 'mpesa'">
                                      <div class="row">
                                          <div class="col-md-4">
                                              <div class="form-group">
                                                  <label><strong>M-pesa phone number:</strong></label> <br/>
                                                  <input class="form-control" type="text" />
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="col-md-12 mt-3" v-if="coverDetails.payment_type == 'card'">
                                      <div class="row">
                                          <div class="col-md-12">
                                              <label><strong>Card Number:</strong></label> <br/>
                                              <input class="form-control" type="text" />
                                          </div>
                                      </div>
                                      <div class="row mt-3">
                                          <div class="col-md-8">
                                              <label><strong>Expiration Date:</strong></label> <br/>
                                              <input class="form-control" type="text" placeholder="mm/yy" />
                                          </div>
                                          <div class="col-md-4">
                                              <label><strong>CVV:</strong></label> <br/>
                                              <input class="form-control" type="text" />
                                          </div>
                                      </div>
                                      <div class="row mt-3">
                                          <div class="col-md-12">
                                              <label><strong>Card Holder Name:</strong></label> <br/>
                                              <input class="form-control" type="text" />
                                          </div>
                                      </div>
                                  </div>

                              </div>
                          </div>
                      </tab-content>
                  </form-wizard>

              </div>
            </div>
          </div>
      </div>


  </div>
</template>

<script>

    import carBrands from "./car_brands";

  export default {
    name: 'home',
    data () {
      return {
          coverDetails:{
              vehicleReg:"",
              fullNames:"",
              mobileNumber:"",
              idType:"",
              identificationNumber:"",
              email:"",
              gender:"",
              model:"",
              payment_type:""
          },
          car_brands:carBrands,
      };
    },

    methods:{
        nextStep(e){
            console.log("Next step");
            console.log(e);
        },
        previousStep(e){
            console.log("Previous step");
            console.log(e);
        },
        submitCover(){

        }
    }
  }
</script>

<style scoped>
    table tr th{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    fieldset.custom-border {
        border: 1px groove #ddd !important;
        padding: 0 1.4em 1.4em 1.4em !important;
        margin: 0 0 1.5em 0 !important;
        -webkit-box-shadow: 0px 0px 0px 0px #000;
        box-shadow: 0px 0px 0px 0px #000;
    }

    legend.custom-border {
        width: auto;
        padding: 0 10px;
        border-bottom: none;
    }
    .vue-step-wizard{
        width: 100% !important;
    }
    .tabStatus{
        background-color: #002563 !important;
    }
    .tabLabel{
        font-weight: bolder;
    }

</style>
